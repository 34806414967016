// a.button {
//     height: 35px;
// }

.button {
    height: 50px;

    outline: none;
    border: none;

    border-radius: 20px;

    color: white;
    font-size: 16px;

    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    text-decoration: none;
    cursor: pointer;

    background-color: #D2DBE2;

    padding: 0px 16px;

    div {
        display: flex;
        align-items: center;

        margin-right: 15px;

        p {
            white-space: nowrap;
        }
    }

    img {
        margin-right: 13px;
    }

    svg {
        transform: rotate(-90deg);
    }

    &:hover {
        background-color: #151515;
    }

    &--can-be-mobile {
        width: 50px;
        border-radius: 50%;
        padding: 0px;

        justify-content: center;

        p {
            display: none;
        }

        div, img {
            margin-right: 0px;
        }

        svg {
            display: none;
        }

        @include phone {
            border-radius: 20px;
            justify-content: space-between;
            padding: 0px 16px;
            width: fit-content;

            p {
                display: block;
            }

            svg {
                display: block;
            }

            div {
                margin-right: 15px;
            }

            img {
                margin-right: 13px;
            }
        }
    }

    &--mobile {
        width: 50px;
        height: 50px;

        max-width: 50px !important;
        min-width: 50px !important;

        border-radius: 50%;
        padding: 0px;

        justify-content: center;

        p {
            display: none;
        }

        div, img {
            margin-right: 0px;
        }

        svg {
            display: none;
        }
    }
}

.button__small {
    height: 40px;
}

.button__arrow {
    margin-top: unset !important;
    margin-bottom: unset !important;
}

.button__fit {
    width: fit-content;
}

.button--nocomments {
    border: 1px solid #C6C7D1;
    color: #C6C7D2 !important;
}

.button--white, .button--nocomments {
    background-color: white;
    color: black;

    &:hover {
        background-color: darken(#ffffff, 10%) !important;
    }
}

.button--disabled {
    background-color: #F7F7F7 !important;

    color: #C6C7D2;

    svg {
        path {
            fill: #C6C7D2;
        }
    }
}

.button--view, .button--unread, .button--document-upload {
    background-color: $color-orange;

    &:hover{
        background-color: $color-orange-hover;
    }
}

.button--create, .button--proceed, .button--upload, .button--modify, .button--send, .button--add, .button--duplicate, .button--comment, .button--export {
    background-color: $color-blue;

    &:hover {
        color: #FFFFFF;

        background-color: $color-blue-hover;
    }
}

.button--cancel {
    background-color: $color-dark-grey;

    &:hover {
        background-color: $color-dark-grey-hover;
    }
}

.button--percent, .button--settings {
    background-color: $color-dark;

    &:hover {
        background-color: $color-dark;
    }
}

.button--image, .button--score, .button--history {
    background-color: $color-darker-grey;

    &:hover {
        background-color: $color-darker-grey-hover;
    }
}

.button--password, .button--untoggled {
    background-color: $color-light-grey;
    color: #354859;

    .button__arrow path {
        fill: #354859;
    }

    &:hover {
        background-color: $color-light-grey-hover;
        color: white;

        svg, img {
            filter: grayscale(1) invert(1) brightness(1);
        }
    }
}

.button--message {
    background-color: $color-grey;

    &:hover {
        background-color: $color-grey-hover;
    }
}

.button--green {
    background-color: $color-green !important;

    &:hover{
        background-color: $color-green-hover !important;
    }
}

.button--blue {
    background-color: $color-blue !important;

    &:hover{
        background-color: $color-blue-hover !important;
    }
}

.button--save {
    background-color: $color-green;

    &:hover{
        background-color: $color-green-hover;
    }
}

.button--idle {
    background-color: $button-idle;

    color: black;

    img {
        filter: invert(100%);
    }

    &:hover {
        color: white;

        img {
            filter: invert(0%);
        }
    }
}

.button--delete {
    background-color: $color-red;

    &:hover{
        background-color: $color-red-hover;
    }
}

.button--inactive {
    background-color: $button-inactive;
}

.button--confirm {
    background-color: $button-confirm;
}
