.state {
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.state__empty {
    height: 400px;
}