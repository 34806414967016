.images-popup {
    padding: 0px;

    .Dropdown-root {
        width: 300px;
        margin-right: 0px;
        margin: 0px 10px;
    }

    .Dropdown-control {
        height: 30px !important;
    }

    .content-searcher__bottom {
        justify-content: left;

        button {
            width: fit-content;
            white-space: nowrap;
        }
    }

   .popup__inner {
       max-width: 100%;
       padding: 0px !important;

       margin: 0px;

       background-color: white;

       border-radius: $border-radius;

       @media (min-width: 451px) {
        max-width: 1246px !important;
        margin: auto !important;
       }
   }

   @media (min-width: 451px) {
    padding: 50px 0px;
   }
}

.images-popup__head {
    height: 76px;

    background-color: white;
    border-radius: $border-radius $border-radius 0px 0px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    position: relative;

    padding: 0px 20px;
    box-sizing: border-box;

    h2 {
        font-size: 21px;
        font-weight: 500;

        position: absolute;
        left: 50%;

        transform: translateX(-50%);
    }
}

.images-popup__close {
    color: $color-blue;
    font-size: 16px;

    display: flex;
    align-items: center;

    img {
        margin-right: 10px;
        transform: rotateY(180deg);
        filter: invert(43%) sepia(46%) saturate(6600%) hue-rotate(200deg) brightness(95%) contrast(93%);
    }

    &:hover {
        cursor: pointer;
    }
}

.images-browse {
    width: 100%;
    height: 621px;

    border-radius: $border-radius $border-radius 0px 0px;

    display: flex;
    flex-direction: column;

    box-sizing: border-box;

    @media (min-width: 451px) {
        flex-direction: row;
        justify-content: space-between;
    }
}

.images-browse__section {
    width: 100%;

    margin-right: 0px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    padding: 18px;
    padding-bottom: 0px;

    box-sizing: border-box;
}

.images-browse__row {
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;

    margin-bottom: 40px;

    .search-box__outer {
        width: 100%;

        border: 1px solid #EFF2F4;
        border-radius: 40px;

        .search-box {
            margin-top: 0px;
            border-radius: 40px;
        }
    }

    .Dropdown-root {
        height: 60px;
    }

    .Dropdown-control {
        height: 56px !important;

        background-color: #F5F8FA !important;
        border: 1px solid $border-color;
    }

    .Dropdown-menu {
        background-color: #F5F8FA !important;
        border: 1px solid $border-color;
    }
}

.images-browse__outer {
    width: 100%;
}

.images-browse__display-head {
    display: flex;
    flex-direction: column;

    button, .images-browse__button {
        padding: 27.5px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        border-bottom: 1px solid #D2DBE2;
        outline: none;
        background-color: transparent;
        color: $color-blue;
        font-size: 16px;
        cursor: pointer;

        &:last-child {
            border-bottom: none;
            color: $color-grey;
            border-radius: 0px 0px $border-radius $border-radius;
        }

        &:hover {
            background-color: darken(#FFFFFF, 10%);
        }
    }
}

.images-browse__display-text {
    box-sizing: border-box;
    padding: 30px;

    p {
        &:first-child {
            font-size: 12px;
            color: rgba(0, 0, 0, 0.3);
            margin-bottom: 5px;
        }

        &:last-child {
            font-size: 20px;
            font-weight: 300;
            color: black;
        }
    }
}

.images-browse__list {
    width: 100%;

    min-height: 396px;
    max-height: 396px;

    overflow: auto;
    overflow-y: auto;

    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
}


.images-browse__box {
    width: 100%;
    height: 100%;

    background-color: #F7F7F7;

    border-radius: $border-radius;

    margin-top: 20px;

    padding-top: 30px;
    box-sizing: border-box;

    @media (min-width: 451px) {
        margin-top: 0px;
        width: 371px;

        display: flex;
        justify-content: center;
    }
}

.images-browse__display, .images-browse__filter {
    width: 100%;
    height: fit-content;

    @media (min-width: 451px) {
        width: 296px;
    }
}

.images-browse__display-image {
    width: 100%;
    height: 233px;

    border-radius: $border-radius $border-radius 0px 0px;

    //background-color: black;

    display: flex;
    justify-content: center;
    align-items: center;

    div {
        width: 100%;
        height: 100%;

        background-size: cover !important;
        background-repeat: no-repeat;
        background-position: center top;

        border-radius: $border-radius $border-radius 0px 0px;
    }

    img {
        width: 30px;
        height: 30px;
    }
}

.images-browse__filter {
    padding: 16px;
}

.images-browse__filter-title {
    font-size: 14px;
}

.images-browse__filter-subtitle {
    margin-top: 20px;
    font-size: 20px;
    font-weight: 300;
}

.images-item {
    background-color: #F5F8FA;
    border-radius: $border-radius;

    width: 100%;
    height: 178px;
    max-width: 178px;

    margin: 10px;
    background-size: cover !important;

    box-sizing: border-box;
    position: relative;

    cursor: pointer;

    &:hover {
        .images-item__select {
            display: flex;
        }
    }
}

.images-item--selected {
    border: 3px solid $color-blue;

    .images-item__select {
        display: flex;
        background-color: $color-blue;
    }
}

.images-upload {
    border-radius: 0px 0px $border-radius $border-radius;

    background-color: white;

    #uppy-upload {
        width: 100%;
        padding: 18px;

        box-sizing: border-box;

        [data-uppy-drag-drop-supported=true] .uppy-Dashboard-AddFiles {
            border: none !important;
        }

        .uppy-Dashboard-inner {
            width: 100% !important;

            background-color: white;
        }

        .uppy-Dashboard-inner, .uppy-Dashboard-innerWrap, .uppy-StatusBar-actions {
            border-radius: 0px 0px $border-radius $border-radius;
        }

        .uppy-Dashboard-AddFiles-title {
            flex-direction: column;

            button {
                margin-top: 20px;
            }

            @include flex-center;
        }
    }
}

.tab-item {
    margin: 0px 5px;

    padding: 5px 10px;

    background-color: $link-hover;

    cursor: pointer;

    &:hover {
        background-color: $link-active;
    }
}

.popup__tabs {
    width: 100%;

    border: 1px solid $border-color;

    border-radius: $border-radius $border-radius 0px 0px;

    background-color: white;

    padding: 30px;

    box-sizing: border-box;

    justify-content: center;

    @include flex-row-centered;
}

.images-browse__viewDetails {
    width: 100%;
    height: 100%;

    background-color: black;

    border-radius: $border-radius;

    position: relative;
}

.viewDetails__back {
    width: 119px !important;

    box-shadow: $box-shadow;

    img {
        transform: rotateY(180deg);
        filter: invert(100%);
        margin-left: 0px;
    }
}

.viewDetails__top {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;

    div {
        width: 100%;
        height: 100%;

        background-size: contain !important;
        background-repeat: no-repeat !important;
        background-position: center top !important;
    }
}

.viewDetails__bar {
    width: calc(100% - 60px);

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    position: absolute;

    top: 20px;

    padding: 0px 30px;

    button {
        width: 200px;
    }

    .images-popup__close {
        color: white;

        img {
            filter: none;
        }
    }



    &:hover {
        cursor: pointer;
    }
}

.viewDetails__bottom {
    width: 100%;
    height: fit-content;

    position: absolute;

    bottom: 0px;

    background-color: gray;

    border-radius: 0px 0px $border-radius $border-radius;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    box-sizing: border-box;

    padding: 30px;
    //padding-left: 80px;

    .input-outer {
        width: 100%;
        margin-right: 50px;
    }

    @media (min-width: 451px) {
        height: 168px;
    }
}

.viewDetails__info {
    width: 100%;

    display: flex;
    flex-direction: column;

    @media (min-width: 451px) {
        flex-direction: row;
    }
}

.viewDetails__info-item {
    margin-bottom: 12px;
    max-width: 100%;

    p {
        @include text-shorten;

        max-width: 100%;

        &:first-child {
            font-size: 12px;
            color: rgba(255, 255, 255, 0.4);
            margin-bottom: 5px;
        }

        &:last-child {
            font-size: 20px;
            font-weight: 300;
            color: white;
        }
    }

    &:last-child {
        margin-bottom: 0px;
    }
}

.viewDetails__info-section {
    margin-right: 56px;
    max-width: 150px;
    margin-bottom: 10px;

    &:last-child {
        margin-right: 0px;
    }

    @include desktop {
        margin-bottom: 0px;
    }
}

.viewDetails__buttons {
    button {
        width: 200px;
        justify-content: center;

        background-color: $color-green;

        &:last-child {
            background-color: transparent;
            color: $color-grey;
        }
    }
}

